<template>
  <div id="app" ref="app">
    <!-- <div class="float_box">
      <button @click="onPostMessageClick">传给父组件message</button>
    </div>
    <wallmap ref="wallmap" :baseMapIndex="1"></wallmap>
    <div class="fullscreen_box">
      <img width="30px" src="/fullscreen.png" @click="onFullscreenClick" />
    </div> -->
    <HelloWorld />
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'
export default {
  components:{
    HelloWorld
  },
  name: "App",
  data() {
    return {
      isFullscreened: false,
      fullscreenAble:
        document.fullscreenEnabled ||
        document.mozFullScreenEnabled ||
        document.webkitFullscreenEnabled ||
        document.msFullscreenEnabled,
    };
  },
  mounted() {
    // window.addEventListener('message',this.receviceMessage)
  },
  methods: {
    receviceMessage(e) {
      console.log('iframe recevice message：',e.data)
    },
    onPostMessageClick() {
      window.parent.postMessage({
        type:"check",
        data:"1231231"
      },"*")

    },
    addBillBoard() {
      console.log(' this.$refs.wallmap', this.$refs.wallmap)
      let wallmap = this.$refs.wallmap;
      let LonLat = { lon: 118.1501521, lat: 36.35357739 };
      let lon = LonLat.lon;
      let lat = LonLat.lat;
      let image = "/dun.png";
      let option = {
        name: "test",
      };
      wallmap.addBillBoard(lon, lat, image, option);
    },
    onFullscreenClick() {
      if(!this.fullscreenAble){
         console.log(document)
        return
      }
      if (this.isFullscreened) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          // Firefox
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          // Chrome, Safari and Opera
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          // Internet Explorer and Edge
          document.msExitFullscreen();
        }
        this.isFullscreened = false;
      } else {
        const element = this.$refs.app;
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.mozRequestFullScreen) {
          // Firefox
          element.mozRequestFullScreen();
        } else if (element.webkitRequestFullscreen) {
          // Chrome, Safari and Opera
          element.webkitRequestFullscreen();
        } else if (element.msRequestFullscreen) {
          // Internet Explorer and Edge
          element.msRequestFullscreen();
        }
        this.isFullscreened = true;
      }
    },
  },
};
</script>

<style>
html,
body {
  height: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100%;
  position: relative;
  text-align: center;
}

* {
  padding: 0;
  margin: 0;
}
</style>

<style scoped>
.fullscreen_box {
  position: absolute;
  right: 30px;
  top: 30px;
}
.float_box {
  position: absolute;
  top: 30px;
  left: 30px;
  z-index: 9999;
}
</style>
