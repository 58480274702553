<template>
  <div class="hello">
    <!-- <code> npm i html5-qrcode </code> -->

    <!-- <h1>方法一：</h1> -->
    <!-- <Html5Qrcode @onSuccess="onSuccess" @onError="onError" /> -->
    <!-- <br />
    <br /> -->

    <!-- <h1>方法二：</h1>
    <QrcodeScanner @onSuccess="onSuccess" @onError="onError"/> -->

    <!-- <h1>方法三：</h1> -->
    <!-- <code>npm i quagga</code>
    <QuaggaScanner @onSuccess="onSuccess" @onError="onError" /> -->

    <!-- <h1> 方法四：</h1> -->
    <code>npm install @undecaf/zbar-wasm@0.10.1</code>
    <ZbarWarm @onSuccess="onSuccess" @onError="onError" />
    <template v-if="result">
      <h6>result:</h6>
      <p>{{ result }}</p>
      <p>{{ decodedResult }}</p>
    </template>
  </div>
</template>

<script>
// import QrcodeScanner from "./QrcodeScanner.vue";
// import Html5Qrcode from "./Html5Qrcode.vue";
// import QuaggaScanner from "./QuaggaScanner.vue";
import ZbarWarm from "./ZbarWarm.vue";
export default {
  name: "HelloWorld",
  components: {
    // QrcodeScanner,
    // Html5Qrcode,
    // QuaggaScanner,
    ZbarWarm,
  },
  data() {
    return {
      result: "",

      decodedResult: "",
    };
  },
  methods: {
    onSuccess(result, decodedResult) {
      console.log("onSuccess", result, decodedResult);
      this.result = result;
      this.decodedResult = JSON.stringify(decodedResult);
    },
    onError(error) {
      console.log("onError", error);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
