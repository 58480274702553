<template>
  <div>
    <div>
      <button @click="scan">扫一扫</button>
      <button @click="stopScan">停止扫描</button>
    </div>
    <br />
    <div>
      <input type="file" @change="onChangeFile" accept="image/*" />
      <button @click="scanImage">扫描图片</button>
    </div>
    <div class="zbar_warm_viewpoint">
      <video ref="video" class="video" mutd autoplay playsinline></video>
      <img class="scanimage" ref="scanimage" :src="imgUrl" />
      <canvas ref="canvas" class="canvas"></canvas>
      <div v-if="scanning" class="loading"></div>
    </div>
  </div>
</template>
<script>
import {
  scanImageData,
  ZBarSymbolType,
  ZBarConfigType,
  getDefaultScanner,
  ZBarScanner,
} from "@undecaf/zbar-wasm";

export default {
  data() {
    return {
      scanning: false,
      img: null,
      imgUrl: null,
      cameraStream: null,
      requestAnimationFrameId: null,
      scanner: null,
    };
  },

  mounted() {
    getDefaultScanner().then((scanner) => {
      // console.log(scanner);
      const new_scanner = new ZBarScanner(scanner.ptr, scanner.inst);

      // disabled all the symbols
      scanner.setConfig(
        ZBarSymbolType.ZBAR_NONE,
        ZBarConfigType.ZBAR_CFG_ENABLE /* The field you want to change */,
        0 /* 0 for disable */
      );

      scanner.setConfig(ZBarSymbolType.ZBAR_CODE128, ZBarConfigType.ZBAR_CFG_ENABLE, 1);
      scanner.setConfig(ZBarSymbolType.ZBAR_CODE39, ZBarConfigType.ZBAR_CFG_ENABLE, 1);
      scanner.setConfig(ZBarSymbolType.ZBAR_EAN13, ZBarConfigType.ZBAR_CFG_ENABLE, 1);
      this.scanner = new_scanner;
    });
  },
  methods: {
    onChangeFile(e) {
      if (this.$refs.canvas) {
        const canvas = this.$refs.canvas;
        const ctx = canvas.getContext("2d");
        ctx.clearRect(0, 0, canvas.width, canvas.height);
      }
      this.img = e.target.files[0];

      this.imgUrl = window.URL.createObjectURL(this.img);
      // scanImageData(e.target.files[0]).then((res) => {
      //   console.log("res", res);
      // });
    },
    scanImage() {
      console.log("imgUrl", this.imgUrl, this.$refs.scanimage);
      this.detect(this.$refs.scanimage);
    },
    scan() {
      this.imgUrl = null;
      if (this.scanning) return;
      console.log("12312");
      navigator.mediaDevices
        .getUserMedia({ audio: false, video: { facingMode: "environment" } })
        .then((stream) => {
          this.cameraStream = stream;
          const video = this.$refs.video;
          video.setAttribute("autoplay", true);
          video.setAttribute("muted", true);
          video.setAttribute("playsinline", true);
          console.log("mediaDevices steam true", stream);

          video.srcObject = stream;
          this.detectVideo();
          video.play();
        })
        .catch((e) => {
          this.$emit("onError", e);
        });
    },
    detectVideo() {
      this.scanning = true;
      const source = this.$refs.video;
      this.detect(source)
        .then((e) => {
          if (e === "done") return;
          this.requestAnimationFrameId = requestAnimationFrame(() => {
            this.detectVideo();
          });
        })
        .catch((e) => {
          this.$emit("onError", e);
        });
    },

    detect(source) {
      console.log("detect", source.naturalWidth || source.videoWidth || source.width);
      const canvas = this.$refs.canvas;
      const ctx = canvas.getContext("2d");
      canvas.width = source.naturalWidth || source.videoWidth || source.width;
      canvas.height = source.naturalHeight || source.videoHeight || source.height;
      if (canvas.width && canvas.height) {
        const offCanvas = new OffscreenCanvas(canvas.width, canvas.height);
        const offCanvasCtx = offCanvas.getContext("2d") || ctx;
        offCanvasCtx.drawImage(source, 0, 0);
        const imageData = offCanvasCtx.getImageData(0, 0, canvas.width, canvas.height);

        return scanImageData(imageData, this.scanner).then((symbols) => {
          console.log(12321);

          symbols.forEach((symbol) => {
            // const lastPoint = symbol.points[symbol.points.length - 1];
            const xs = symbol.points.map((e) => e.x);
            const ys = symbol.points.map((e) => e.y);
            const centerX = Math.ceil((Math.min(...xs) + Math.max(...xs)) / 2);
            const centerY = Math.ceil((Math.min(...ys) + Math.max(...ys)) / 2);
            // const centerPoint = [certerX,centerY]
            console.log(centerX, centerY);
            ctx.beginPath();
            const radius = Math.max(Math.min(canvas.height, canvas.width) / 100, 10);
            console.log("radius", radius);
            ctx.arc(centerX, centerY, radius, 0, 2 * Math.PI, false);
            ctx.fillStyle = "green";
            ctx.fill();
            ctx.lineWidth = 1;
            ctx.strokeStyle = "#fff";
            ctx.stroke();

            // ctx.moveTo(lastPoint.x, lastPoint.y);
            // symbol.points.forEach((point) => ctx.lineTo(point.x, point.y));

            // ctx.lineWidth = Math.max(Math.min(canvas.height, canvas.width) / 100, 1);
            // ctx.strokeStyle = "#00e00060";
            // ctx.stroke();
          });
          if (symbols && symbols.length) {
            symbols.forEach((s) => (s.rawValue = s.decode("utf-8")));
            const result = JSON.stringify(symbols, null, 2);
            console.log("result", result, symbols[0].rawValue);
            this.$emit("onSuccess", symbols[0].rawValue);
            this.timer = setTimeout(() => {
              this.stopScan();
            }, 500);
            return "done";
          } else {
            this.$emit("onSuccess", "");
          }
        });
      } else {
        return Promise.resolve();
      }
    },
    stopScan() {
      this.scanning = false;
      if (this.cameraStream) {
        this.cameraStream.getTracks()[0].stop();
        this.cameraStream = null;
        if (this.$refs.video) {
          this.$refs.video.srcObject = null;
        }
        if (this.$refs.canvas) {
          const canvas = this.$refs.canvas;
          const ctx = canvas.getContext("2d");
          ctx.clearRect(0, 0, canvas.width, canvas.height);
        }
      }

      if (this.requestAnimationFrameId) {
        console.log("stop requestAnimationFrameId");
        cancelAnimationFrame(this.requestAnimationFrameId);
        this.requestAnimationFrameId = null;
      }
      if (this.timer) {
        clearTimeout(this.timer);
      }
    },
  },
  destroyed() {
    this.stopScan();
  },
};
</script>
<style scoped>
.zbar_warm_viewpoint {
  display: inline-block;
  position: relative;
  .canvas {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  .video,
  .scanimage {
    max-width: 100%;
  }
}
.img-box {
  position: relative;
  width: 300px;
  height: 200px;
  margin: 200px auto;
}
.loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
      to right,
      transparent 0%,
      #9ff92180 40%,
      #9ff92180 60%,
      transparent 100%
    ),
    linear-gradient(90deg, #ffffff10 1px, transparent 0),
    linear-gradient(#ffffff10 1px, transparent 0), linear-gradient(transparent, #98ff0710);
  background-size: 100% 2px, 10% 100%, 100% 8%, 100% 100%;
  background-repeat: no-repeat, repeat, repeat, no-repeat;
  background-position: 0 0, 0 0, 0 0, 0 0;
  clip-path: polygon(0% 0%, 100% 0%, 100% 1.5%, 0% 1.5%);
  animation: move 1.5s infinite linear;
}
@keyframes move {
  to {
    background-position: 0 100%, 0 0, 0 0, 0 0;
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }
}
.img-box img {
  display: block;
  width: 100%;
  height: 100%;
}
</style>
