import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false
// import wallmap from "../public/map/wallmap/wallmap.common"
// import "../public/map/wallmap/wallmap.css"
// Vue.use(wallmap)
new Vue({
  render: h => h(App),
}).$mount('#app')



